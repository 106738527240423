<template>
  <div>
    <AboutUsHero title="History" />

    <div class="" style="gap: 25px;">
      <div class="d-flex flex-column">
        <div class="p-2" style="background-color: white;">
          <div class="mb-2">
            <div>
              <h1 style="color: #1735bf; font-weight: 400; border-bottom: 10px solid #1735bf; width: 220px;">HISTORY</h1>
            </div>
            <div style="background-color: #1735bf; width: 100%; height: 2.5px; margin-top: -13px;"></div>
          </div>

          <p class="text-lg mb-1">
            In the 1970s increasing numbers of the sons and daughters of the Church entered into secondary and tertiary institutions for higher education. Although the emerging breed of students loved their church and the spiritual warmth which she offered, they were compelled by the circumstances in which they found themselves to drift to other fellowships where they would gain acceptance
          </p>

          <p class="text-lg mb-1">
            The unfortunate side effects that secular education has had on the spiritual standing of the ‘elite’ members of our churches was enormous. Teenagers who had just passed through the Sunday School system of the church into secondary schools were confronted with the realities of the freedom of being away from home and out of the supervision of parents.
          </p>
        </div>

        <div class="p-2" style="background-color: white;">
          <div class="mb-2">
            <div class="w-100">
              <h1 style="color: #1735bf; font-weight: 400; border-bottom: 10px solid #1735bf; width: 300px;">THE IDENTIFIABLE GAP</h1>
            </div>
            <div style="background-color: #1735bf; width: 100%; height: 2.5px; margin-top: -13px;"></div>
          </div>
            <p class="text-lg mb-1">
              Though Pentecostalism was producing a healthy influence over the less educated segment of the population, it virtually had no impact on the higher institutions of learning.
            </p>

            <p class="text-lg mb-1">
              Up to about 1976, for example, there was no Pentecostal Fellowship in any of the three Universities of the country. Pentecostalism was simply made unattractive to the elite.
            </p>
        </div>

        <div class="p-2" style="background-color: white;">
          <div class="mb-2">
            <div class="w-100">
              <h1 style="color: #1735bf; font-weight: 400; border-bottom: 10px solid #1735bf; width: 300px;">THE EARLY DAYS</h1>
            </div>
            <div style="background-color: #1735bf; width: 100%; height: 2.5px; margin-top: -13px;"></div>
          </div>
            <p class="text-lg mb-1">
             In Kumasi Apostle J. C Quaye of blessed memory from about 1969 started gathering students and inspiring them to keep to the faith.
            </p>

            <p class="text-lg mb-1">
              In 1974, the late Apostle A. T. Nartey also started organizing the students at Cape Coast especially during vacations and gave them fatherly encouragement to keep the faith. The need for students’ wing of the Church was therefore somehow borne in the minds of many.
            </p>

            <p class="text-lg mb-1">
             In 1976, the campus of the University of Ghana Legon, witnessed the formation of the Legon Pentecostal Union (LPU) as the first ever pentecostal fellowship on any University campus
            </p>

            <p class="text-lg mb-1">
              In 1978 some members of the Church at the University of Science and Technology organized themselves into a Church of Pentecost Students Group which adopted the name ‘Pentecost Students Association’ whereas the Legon Pentecostals Union embraced members of other churches of the Ghana Pentecostal Council. That same year it was firmly decided after some discussions between Brothers J. S. Gyimah, Peter Ohene Kyei & J. A. Larkai that concrete
steps should be taken to establish a students’ movement within the church to cater for the challenges of students.
            </p>

            <p class="text-lg mb-1">
              The first General Secretary of the Church, Reverend J. Egyir-Paintsil who had actively followed the work of the Legon Fellowship, was contacted by the three brothers and the idea was fully discussed with him.
            </p>

            <p class="text-lg mb-1">
              Rev. Egyir-Paintsil carried the discussion further on the need for the establishment of the student movement in the Church and the Greater Accra regional Presbytery accepted the idea at one of its meetings held at Abossey Okai, Accra. The presbytery readily agreed to finance a Delegates meeting estimated to cost Two thousand five hundred cedis (¢2,500).
            </p>

            <p class="text-lg mb-1">
              Elder J. E. K. Antiri, an educationist who proved a real life wire for PENSA was by the grace of God appointed as a first Mentor and Leader. A delegates meeting was thus convened at Accra Academy on 21st to 23rd August 1979 under the chairmanship of Elder J E. K Antiri. At this meeting PENSA was formalized and an interim National Committee was set up to plan a National Conference in 1980 where PENSA would be formally inaugurated and a National Executive elected.
            </p>

            <p class="text-lg mb-1">
              On 23rd August, 1980 a General Conference was held at Prempeh College, Kumasi. About 800 participants were in attendance, a constitution was promulgated and PENSA formally inaugurated. At the 18th session of the General Council, (1981) the Church unanimously accepted the movement.
            </p>
        </div>

        <div class="p-2" style="background-color: white;">
          <div class="mb-2">
            <div class="w-100">
              <h1 style="color: #1735bf; font-weight: 400; border-bottom: 10px solid #1735bf; width: 300px;">PENSA TODAY</h1>
            </div>
            <div style="background-color: #1735bf; width: 100%; height: 2.5px; margin-top: -13px;"></div>
          </div>
            <p class="text-lg mb-1">
              Currently, PENSA operates under the Youth Ministry of the Church and has a membership of over 150,000 in over 190 tertiary and 600 second cycle institutions in Ghana. There are 18 sectors, headed by Travelling Secretaries who are ordained fulltime ministers of the Church of Pentecost. There are 4 institutions with a Resident Campus Minister in charge
            </p>

            <p class="text-lg mb-1">
              The hallmark of PENSA activities were the biennial regional and national conferences which were characterized by spiritually edifying programmes.
            </p>
            <p class="text-lg mb-1">
              The following National Conferences have been held:
              <ul>
                <li>1980 Prempeh College, Kumasi</li>
                <li>1982 Ghana Secondary School, Koforidua</li>
                <li>1985 Wesley College, Kumasi, Theme: Come, Go and Tell</li>
                <li>1987 University of Science and Technology, Kumasi, Theme: The
                  Holy Spirit and You
                </li>
                <li>
                  1990 Aburi Girls Secondary School, Aburi; Theme: The Unchanging
                  God.
                </li>
              </ul>
            </p>
            <p class="text-lg mb-1">
              By 1990 the records indicated that almost every secondary
              school in the country has been visited by PENSA at least once. With the vibrant growth of PENSA in Ghana, there was the need to have
              leadership to spearhead activities of all PENSA groups at the national level.
            </p>

            <p class="text-lg mb-1">
             The Coordinating Team System was then implemented. The team had the responsibility of planning and organizing regional and national conferences. The conference was dubbed the I.T.I PENSA Conference since it was mainly patronized by tertiary students
            </p>

            <p class="text-lg mb-1">
             However, between the year 2011 and 2013, the number of PreTertiary (secondary school) students who attended the I.T.I PENSA conference was steadily increasing. This informed leadership to reconsider the name I.T.I PENSA to PENSA GHANA in order to capture all students at the various levels of education.
            </p>

            <p class="text-lg mb-1">
              In the advent of the creation of more sectors, the leadership at the national level is rotated among sectors instead of institutions. Venue for hosting the conference in 2014 was permanently moved from the campuses to the Pentecost Convention Centre due to difficulties in getting venues to host the conference. Huge patronage since then has further necessitated additional centres being added to enhance access and proximity
            </p>
        </div>

        <div class="p-2" style="background-color: white;">
          <div class="mb-2 w-100">
            <h1 style="color: #1735bf; font-weight: 400; border-bottom: 10px solid #1735bf; max-width: 600px;">THE IMPACT OF PENSA IN THE CHURCH AND SOCIETY</h1>
            <div class="w-100">
            </div>
            <div style="background-color: #1735bf; width: 100%; height: 2.5px; margin-top: -13px;"></div>
          </div>
            <p class="text-lg mb-1">
              By the grace of God, PENSA is not only impacting lives in the church, it is also
              touching lives in our societies as well. These are being done in numerous ways:
            </p>

            <p class="text-lg mb-1">
              Massive evangelism, with signs and wonders, winning souls whose lives are
              made better and beneficial to the church and the community. With this, many
              assemblies in The CoP have been opened.
            </p>
            <p class="text-lg mb-1">
              The transforming of many young people’s spiritual lives through effective
              teaching, prayers and discipleship and the raising of leaders who continue
              to raise high the banner of Christ and the church. This is evidenced by the
              numerous officers and Ministers of the church who have had training in PENSA.
            </p>
        </div>
      </div>
      
      <div class="aims-and-objectives p-2">
        <b-row class="d-flex align-items-start">
          <b-col md="4">
            <h1 style="border-bottom: 5px solid #1735bf; width: 120px;">Aims</h1>
            <ul class="">
              <li class="text-lg">To foster interaction among members within and outside the various campuses</li>
              <li class="text-lg">To deepen and strengthen personal faith in Christ Jesus, and to enhance the mutual growth of members through the study of the Bible, prayer and fellowship.</li>
              <li class="text-lg">For proper integration of students and alumni into the Church of Pentecost (The Church)</li>
              <li class="text-lg">To help establish PENSA in all Pre -Tertiary, Vocational, Technical and Tertiary Institutions.</li>
              <li class="text-lg">To curb the drift of students and alumni into other churches.</li>
              <li class="text-lg">To help gather resources for field and missionary work.</li>
              <li class="text-lg">To ensure effective communication between the Church and the Group, as well as within the Group.</li>
            </ul>
          </b-col>
          <b-col md="4" class="mt-5">
            <b-img fluid src="@/assets/images/pages/campus.jpg" />
          </b-col>
          <b-col md="4">
            <h1 style="border-bottom: 5px solid #1735bf; width: 220px;">Objectives</h1>
            <ul class="">
              <li class="text-lg">To organize programs to meet the needs of members in the Tertiary institutions, such as conferences, joint evangelistic crusades, seminars, workshops, orientation for National Service Personnel, etc.</li>
              <li class="text-lg">To promote the establishment of the alumni body for effective integration into the Church and to help finance programs.</li>
              <li class="text-lg">To provide assistance to members of the Church in Second Cycle Institutions in the form of seminars, crusade, vacation classes, and career guidance, etc. to inspire, motivate and encourage such members.</li>
              <li class="text-lg">To inculcate into the membership, a lifestyle of modesty and decency.</li>
              <li class="text-lg">To help members identify and develop their ministries and leadership potentials.</li>
              <li class="text-lg">To coordinate the activities of PENSA in other institutions within designated zones.</li>
            </ul>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BImg,
  BCard,
  BMedia,
  BCardText,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
} from "bootstrap-vue";

import AboutUsHero from "@/@core/components/public/AboutUsHero.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BCard,
    BMedia,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    AboutUsHero,
  },
  data() {
    return {
      css: {
        // eslint-disable-next-line global-require, import/no-unresolved
        backgroundImage: `url(${require("@/assets/images/pages/campus.jpg")})`,
        minHeight: "500px",
        objectFit: "contain"
        // backgroundImage: url(`${require('@/assets/images/pages/campus/jpg')}`)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vision-image {
  // max-height: 300px;
  width: 100%;
  object-fit: cover;
}

.vision-text {
  border: 2px solid #ffc000;
  color: #feffff;
  border-radius: 15px;
  max-width: 450px;
  padding: 8px;
  font-size: 16px
}

.vision-title {
  font-size: 2rem;
  font-weight: 400;
  color: white
}

.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
  background: #0f3b66 /* #666666; */
}

.overlay {
  z-index: 9;
  background: rgba(0,0,0,0.5);
  opacity: 0.9;
}

.vision-mission {
  background-size: 100% 400px;
  height: 500px;
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}

.text-yellow {
  color: yellow;
}
.text-lg {
  font-size: 15px;
}
.img-container {
  height: 400px;
  widows: 300px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

ol {
  font-size: 15px;
  li {
    margin-bottom: 10px;
  }
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
}
</style>
